@import '/public/styles/core';

// Layout
.PageLayout {
  padding-top: calc($header-panel-height + 1.5rem);
  width: 100%;
  min-height: calc(100% - $header-panel-height - 1.5rem);
  background: #ffffff;

  @include from-mq-md {
    padding-top: calc($header-panel-height + 1.5rem);
    min-height: calc(100% - $header-panel-height - 1.5rem);
  }
}

.PageWrap {
  @include page-vertical-paddings;
  padding-top: 0 !important;

  .Content {
    position: sticky;
    top: -5rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    background-color: #ffffff;
    z-index: 3;

    @include from-mq-sm {
      top: -2rem;
    }

    @include from-mq-md {
      top: -0.5rem;
    }

    .MainInfo {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1.5rem;

      @include from-mq-md {
        flex-direction: row;
        margin-bottom: unset;

        &.WithMargin {
          margin-bottom: 2rem;
        }
      }

      .FiltersSection {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1rem;
        width: 100%;

        &.NoGap {
          gap: unset;
        }

        @include from-mq-sm {
          flex-direction: row;
          justify-content: space-between;
        }

        @include from-mq-md {
          justify-content: flex-start;
          width: unset;
        }

        .CohortWrapper {
          .SelectCohort {
            width: 100%;

            @include from-mq-sm {
              width: calc(90dvw / 2);
            }

            @include from-mq-md {
              width: 17.5rem;
            }

            @include from-mq-lg {
              width: 18.75rem;
            }
          }
        }
      }

      .RefreshSection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 1rem;

        @include from-mq-md {
          gap: unset;
          width: unset;
        }

        .UpdateStamp {
          color: #7a7a7a;
        }

        .RefreshPage {
          margin-left: 1rem;
        }
      }
    }

    .AnalyticsTabs {
      min-height: auto;
    }
  }
}
